.warning-info-page {
    padding-top: 15em;

    h1 {
        text-align: center;
    }
    p {
        font-weight: 100;
        text-align: center;
    }
}