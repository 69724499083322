iFrame {
    width: 120%;
    height: 65vh;
}

.form-container {
    width: 80%;
}

.terms-container {
    width: 100%;
    height: 50vh;
    overflow-y: auto;
    box-shadow: inset black 0px 1px 6px 0px;
}

.check-label {
    font-size: 1.05em;
}

.check-size {
    width: 1em;
    height: 1em;
}
