body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-hover {
  cursor: pointer;
}

.logo-main {
  width: 140px;
}

.title-main {
  font-size: 1.33em;
  margin-left: 1em;
}

.nav-margin {
  margin: 0 0.5em 0 0;
}

.watermark-outer {
  position: relative;
  width: 0;
  height: 0
}

.watermark-inner {
  position: absolute;
  left: 0;
  bottom: -25px;
  z-index: -1;
}

$width-to-mobile: 767px;
.watermark-secondary
{
  max-width: 200px;
  position:absolute;
  right:10%;
  opacity:1;
  z-index: -1;
}

@media screen and (max-width: $width-to-mobile) {
  .watermark-primary {
    display:none;
  }
  .watermark-secondary {
    max-width: 75px;
    position: absolute;
    top:4%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.toast-container-wrapper {
  .Toastify__toast-container--top-right {
    top: 4em;
  }
  .Toastify__toast {
    border-radius: 0.25em;
  }
}

.react-datepicker-wrapper {
  display: block !important;
}

.start-page-parent {
  overflow-x: auto;
}

.main-panel {
  padding: 75px 15px;
  min-height: calc(101vh - 80px);
}