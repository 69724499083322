.success {
    background: #0eff2225;
    color: #1f8135 !important;
}

.detail {
    background: #42a0a025;
}

.warn {
    background: #ffc107 !important;
}

.error {
    background: #ff000025;
}

.rounded {
    border-radius: 0.5em;
}

.fa {
    font-size: 1.5em;
}
