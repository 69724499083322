.status-page {
    padding-top: 10em;

    h1 {
        text-align: center;
    }
    p {
        font-size: 1.5em;
        font-weight: 100;
        text-align: center;
    }
}
