.menu-button {
    max-width: 350px;
    cursor: pointer;
    background-color: #eceaea;
    border-radius: 0.5em;
    border-style: solid;
    border: #8d8d8d;
    border-width: 3px !important;
    width: 350px;
}

.menu-button:hover {
    background-color: #8d8d8d;
}

.menu-button .fa {
    display: block !important;
    width: 24px !important;
    height: 24px !important;
    visibility: visible !important;
}

.disabled {
    background-color: #f5efef;
}

.disabled:hover {
    background-color: #f1efef !important;
    cursor: default;
}

.disabled p {
    color: #8d8d8d;
}
