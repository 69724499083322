.notification-bar {
    .fa {
        margin-right: 0.75em;
    }
    .notification-bar-button-text {
        font-size: 1rem;
    }
    .notification-bar-sub-text-container {
        margin-left: 1.75em;

        hr {
            margin: 2px;
            width: 67%;
        }
        p {
            font-size: 0.8rem;
            margin: 0;
        }
    }
}