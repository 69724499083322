@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

input {
    margin-bottom: 0px;
    width: inherit;
}
.invalid input {
    border-color: $danger;
    border-width: 1px;
    border-style: solid;
}

.valid input {
    border-color: $success;
    border-width: 1px;
    border-style: solid;
}

.invalid input:focus {
    border-color: $danger;
}

.valid input:focus {
    border-color: $success;
}

.validated-input {
    width: 20%;
    display: table;
    text-align: center;
}
